/**
 * All config. options available here:
 * https://cookieconsent.orestbida.com/reference/configuration-reference.html
 */
import * as CookieConsent from "vanilla-cookieconsent";

window.onload = function() {

    // Enable dark mode
    document.documentElement.classList.add('cc--darkmode');

    CookieConsent.run({
        revision: 1,
        guiOptions: {
            consentModal: {
                layout: "box",
                position: "bottom left",
                equalWeightButtons: true,
                flipButtons: true
            },
            preferencesModal: {
                layout: "box",
                position: "right",
                equalWeightButtons: false,
                flipButtons: true
            }
        },
        categories: {
            necessary: {
                readOnly: true
            },
            analytics: {}
        },
        language: {
            default: "de",
            autoDetect: "browser",
            translations: {
                de: {
                    consentModal: {
                        title: "Cookie gefällig?",
                        description: "Warum und wofür wir die digitalen Guetzli einsetzten, sehen Sie in den Einstellungen.",
                        acceptAllBtn: "Alle akzeptieren",
                        acceptNecessaryBtn: "Alle ablehnen",
                        showPreferencesBtn: "Einstellungen verwalten",
                        footer: "<a href=\"/datenschutz.php\">Datenschutz</a>\n<a href=\"/mietbedingungen.php\">Bedingungen (AGB)</a>"
                    },
                    preferencesModal: {
                        title: "Präferenzen für die Zustimmung",
                        acceptAllBtn: "Alle akzeptieren",
                        acceptNecessaryBtn: "Alle ablehnen",
                        savePreferencesBtn: "Einstellungen speichern",
                        closeIconLabel: "Modal schließen",
                        serviceCounterLabel: "Dienstleistungen",
                        sections: [
                            {
                                title: "Verwendung von Cookies",
                                description: "Wir verwenden Cookies für folgende Zwecke"
                            },
                            {
                                title: "Notwendige Cookies & lokaler Speicher <span class=\"pm__badge\">Immer Aktiviert</span>",
                                description: "Für ausgewählte Funktionen, wie zum Beispiel die Auswahl des Farbschmas, oder Ihr Cookie-Einstellungen, verwenden wir Cookies und den lokalen Speicher Ihres Browsers, um Einstellungen anonym festzuhalten. Diese Daten glangen nicht an uns.",
                                linkedCategory: "necessary"
                            },
                            {
                                title: "Analytische Cookies (Google Analytics)",
                                description: "Zur Verbesserung unserer Website und unseres Services verwenden wir Cookies, um anonyme Informationen über die Nutzung unserer Website zu sammeln. Diese Daten werden an Google Analytics weitergeleitet, bleiben aber auch dort anonym.",
                                linkedCategory: "analytics"
                            },
                            {
                                title: "Weitere Informationen",
                                description: "Für Fragen im Zusammenhang mit der Verwendung von Cookies und Ihre Auswahl, <a class=\"cc__link\" href=\"/kontakt/\">wenden Sie sich bitte an uns</a>."
                            }
                        ]
                    }
                },
                en: {
                    consentModal: {
                        title: "Hello traveller, it's cookie time!",
                        description: "We use cookies for the following purposes",
                        acceptAllBtn: "Accept all",
                        acceptNecessaryBtn: "Reject all",
                        showPreferencesBtn: "Manage preferences",
                        footer: "<a href=\"/datenschutz.php\">Privacy Policy</a>\n<a href=\"/mietbedingungen.php\">Terms and conditions</a>"
                    },
                    preferencesModal: {
                        title: "Consent Preferences Center",
                        acceptAllBtn: "Accept all",
                        acceptNecessaryBtn: "Reject all",
                        savePreferencesBtn: "Save preferences",
                        closeIconLabel: "Close modal",
                        serviceCounterLabel: "Service|Services",
                        sections: [
                            {
                                title: "Cookie Usage",
                                description: "We use cookies for the following purposes."
                            },
                            {
                                title: "Strictly Necessary Cookies <span class=\"pm__badge\">Always Enabled</span>",
                                description: "For selected functions (such as selecting the color scheme) we use cookies and your browser's local storage to store settings anonymously. This data is not passed on to us.",
                                linkedCategory: "necessary"
                            },
                            {
                                title: "Analytics Cookies (Google Analytics)",
                                description: "To improve our website and our service, we use cookies to collect anonymous information about the use of our website",
                                linkedCategory: "analytics"
                            },
                            {
                                title: "More information",
                                description: "For any query in relation to my policy on cookies and your choices, please <a class=\"cc__link\" href=\"/kontakt/\">contact me</a>."
                            }
                        ]
                    }
                }
            }
        }
    }).then(
        function (consent) {
            console.log("User's consent status:", consent);
            // add icon to cookie consent popup
            const ccPopup = document.getElementById('cc-main');
            if (ccPopup) {
                const ccTitle = ccPopup.getElementsByClassName('cm__title');
                const cookieConsentIcon = document.createElement('div');
                cookieConsentIcon.classList.add('cookie-consent-icon');
                cookieConsentIcon.classList.add('fa');
                cookieConsentIcon.classList.add('fa-cookie-bite');
                cookieConsentIcon.classList.add('text-end');
                ccTitle[0].appendChild(cookieConsentIcon);
                console.log('Cookie Consent Icon added');
            }
            else {
                console.log('Cookie Consent Icon not added');
            }
        },
        function (err) {
            console.error(err);
        }
    )

    Alp.CookieConsent = CookieConsent;

    // remove settings button if no cookie consent
    if (!Alp.CookieConsent) {
        const ccSettingsButton = document.getElementById('button-cc-settings');
        ccSettingsButton.remove()
    }

};