require('bootstrap/dist/js/bootstrap.min');
import {Swiper, Navigation, Pagination, Scrollbar, A11y, EffectCoverflow} from 'swiper/bundle';

import { Fancybox } from "@fancyapps/ui";
import { Carousel } from "@fancyapps/ui/dist/carousel/carousel.esm.js";

import JustValidate from 'just-validate';

import barba from '@barba/core';
//import slide from './transitions/slide';

import { gsap } from "gsap";
import { CustomEase } from "gsap/CustomEase";
import { RoughEase, ExpoScaleEase, SlowMo } from "gsap/EasePack";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(RoughEase, ScrollTrigger);
// import { Flip } from "gsap/Flip";
// import { Observer } from "gsap/Observer";
// import { ScrollToPlugin } from "gsap/ScrollToPlugin";
// import { Draggable } from "gsap/Draggable";
// import { MotionPathPlugin } from "gsap/MotionPathPlugin";
//import { EaselPlugin } from "gsap/EaselPlugin";
// import { PixiPlugin } from "gsap/PixiPlugin";
// import { TextPlugin } from "gsap/TextPlugin";

import { computePosition, flip, shift, offset, arrow } from '@floating-ui/dom'; // for tooltips
require('ThemeSwitcher');
require('CookieConsentSetup');

var Alp = Alp || {};
Alp.JustValidate = JustValidate;
Alp.computePosition = computePosition;
Alp.arrow = arrow;
Alp.offset = offset;
Alp.shift = shift;
Alp.flip = flip;

window.Alp = Alp;

document.addEventListener('DOMContentLoaded', function() {

    const enableBarbaTransition = true;
    const enableBarbaLog = false;
    const barbaContainer = document.querySelector('[data-barba="container"]');
    const enableLocoMotiveScroll = false;

    let initialSlide = getCurrentPathNameFirstSlide();

    const flyIn = document.querySelectorAll('.fly-in');
    const fadeInImages = document.querySelectorAll('.image-fade');

    const pageUp = document.getElementById('page-up');

    const video = document.getElementById('vid');
    const videoPoster = document.getElementById('poster-overlay');

    if(videoPoster){
        // hide video poster when video is playing
        video.addEventListener('play', function() {
            videoPoster.classList.add('hidden');
        });
        video.addEventListener('pause', function() {
            videoPoster.classList.remove('hidden');
        });
    }
    else {
        // console.log('No video poster found');
    }

    let currentWeatherIconIndex = 0;

    /* show sticky contact button on right page border */
    const ctaContact = document.getElementById('cta-contact');
    if(ctaContact) {
        gsap.from(ctaContact, {
            x: 100, // Start from off-screen to the left
            opacity: 0,
            duration: 1, // Duration of the animation in seconds
            ease: 'power2.in', // Easing function for a smooth animation
            //ease: 'elastic', // Easing function for a smooth animation
            scrollTrigger: {
                trigger: ctaContact,
                start: 'bottom 55%', // Start the animation when the top of the element is 80% from the top of the viewport
                //end: 'bottom 35%', // End the animation when the bottom of the element is 20% from the top of the viewport
                toggleActions: "play none none none",
                scrub: true, // Smoothly animate the element as the user scrolls
                //markers: true // Enable markers for debugging
            }
        });
    }

    //
    const flyInElements = document.querySelectorAll('.fly-in-element');

    if (flyInElements.length > 0) {
        flyInElements.forEach(flyInElement => {
            gsap.from(flyInElement, {
                x: 400, // Start from off-screen to the left
                opacity: 0,
                duration: 1, // Duration of the animation in seconds
                //ease: 'power2.out', // Easing function for a smooth animation
                ease: 'elastic', // Easing function for a smooth animation
                scrollTrigger: {
                    trigger: flyInElement,
                    start: 'top 45%', // Start the animation when the top of the element is 80% from the top of the viewport
                    //end: 'bottom 35%', // End the animation when the bottom of the element is 20% from the top of the viewport
                    toggleActions: "play none none none",
                    //scrub: true, // Smoothly animate the element as the user scrolls
                    //markers: true // Enable markers for debugging
                    onEnter: () => animateWeatherIcons()
                }
            });
        });
    }

    if (fadeInImages.length > 0) {
        fadeInImages.forEach(image => {
            gsap.from(image, {
                opacity: 0,
                duration: 1, // Duration of the fade-in animation
                ease: 'power1.out', // Easing function for a smooth animation
                scrollTrigger: {
                    trigger: image,
                    start: 'top 80%', // Start the animation when the top of the image is 80% from the top of the viewport
                    end: 'bottom 20%', // End the animation when the bottom of the image is 20% from the top of the viewport
                    toggleActions: 'play none none none', // Play the animation when entering the viewport, reset when leaving
                }
            });
        });
    }


    function barbaLog(message,title='Barba Log') {
        if (enableBarbaLog) {
            console.dir(title, message);
        }
    }

    function contentAnimation() {

        barbaLog('Content Animation');

        if (fadeInImages.length == 0) {
            barbaLog('No content animation needed');
            //console.log('No content animation needed');
            return;
        }
        let tl = gsap.timeline({delay: 0.5});
        tl.to("section .image-fade", { duration: 1.5, opacity: 1, ease: 'power1.out'} );

        //let timeLineSwiper = gsap.timeline();
        //timeLineSwiper.from(".swiper-slide", {opacity: 0});
        //timeLineSwiper.to(".swiper-slide", {duration: 1, opacity: 1, ease: 'power1.out', delay: 1});
    }

    initFancyboxCarousels();
    //contentAnimation();
    initHeaderSwiper(initialSlide);
    intiLocoMotiveScroll();
    initPageUpButton();

    if (enableBarbaTransition && barbaContainer !== null) {
        // https://barba.js.org/docs/
        // https://barba.js.org/docs/advanced/third-party/#Locomotive-scroll

        const swiperSlides = document.querySelectorAll('.swiper-slide');

        barba.init({
            transitions: [{
                name: 'default-transition',
                leave(data) {
                    return gsap.to(data.current.container, {
                        opacity: 0
                    });
                },
                enter(data) {
                    return gsap.from(data.next.container, {
                        opacity: 0
                    });
                },
            }],
            views: [{
                namespace: 'product-page',
                beforeLeave() {
                    if (swiperSlides.length > 0) {
                        return gsap.to(".swiper-slide", {opacity: 0});
                    }
                },
                beforeEnter() {
                    // update the menu based on user navigation
                    //console.log('before enter view product-page');
                },
                afterEnter() {
                    barbaLog('after enter product-page view');
                    if (swiperSlides.length > 0) {
                        return gsap.to(".swiper-slide", {opacity: 1});
                    }
                }
            } /*, {
                // try using namespaces if odd problems occur
                namespace: 'contact-page',
                beforeEnter() {
                    console.log('before enter contact-page view');
                },
                afterEnter() {
                    console.log('after enter contact-page view');
                }
            }*/],
            // not deeded since fancy carousel wrapper has data-barba-prevent="all"
            //prevent: ({ href }) => href.includes('#gallery') || href.endsWith('.jpg') || href.endsWith('.svg'),
        });

        barba.hooks.after(() => {
            //scroll.init();
            if (enableLocoMotiveScroll) {
                scroll.update();
            }
            initialSlide = getCurrentPathNameFirstSlide();
            initHeaderSwiper(initialSlide);
            //contentAnimation();
            initFancyboxCarousels();
            // Scroll to top if the target window.location has no #
            if (!window.location.hash) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }

        });
    }
    else {
        console.log('Barba transition disabled');
        const initialSlide = getCurrentPathNameFirstSlide();
        initHeaderSwiper(initialSlide);
        initFancyboxCarousels();
    }

    function intiLocoMotiveScroll() {
        const locoMotiveContainer = document.querySelector('[data-scroll-container]');
        if(locoMotiveContainer && enableLocoMotiveScroll) {
            console.log('Locomotive Scroll initialized: ' + enableLocoMotiveScroll + '!');
            scroll = new LocomotiveScroll({
                el: document.querySelector('[data-scroll-container]'),
                smooth: true,
                multiplier: 2
            });
        }
    }

    /**
     * Detect the swiper slide position based on the current path name
     * @returns {number}
     */
    function getCurrentPathNameFirstSlide () {
        let slideId = 1;
        let hashName = '';
        const pathName = document.location.pathname;
        switch (pathName) {
            case '/':
                slideId = 1;
                hashName = '#alphuettli';
                break;
            case '/eventmobile/':
                slideId = 2;
                hashName = '#eventwagen';
                break;
            case '/festmobiliar/':
                slideId = 3;
                hashName = '#zubehoer';
                break;
            case '/zelte/':
                slideId = 4;
                hashName = '#zelte';
                break;
            default:
                slideId = 1;
                hashName = '#alphuettli';
        }
        //console.log('pathName: ' + pathName);
        // also add corresponding hash to url
        //document.location.hash = hashName;
        return slideId;
    }

    function initFancyboxCarousels() {
        const carousels = document.querySelectorAll('.fancyCarousel');
        carousels.forEach((carousel) => {

            const slides = carousel.querySelectorAll('.f-carousel__slide');
            const numberOfVisibleSlides = carousel.getAttribute('data-visible-slides') || 5;
            const galleryGroup = carousel.getAttribute('data-gallery-group') || 'gallery';
            let showDots = true;
            if(carousel.getAttribute('data-show-dots') && carousel.getAttribute('data-show-dots') === 'false') {
                showDots = false;
            }

            const options = {
                infinite: true,
                initialSlide: 1,
                transition: 'fade',
                Dots: showDots,
                center: false,
                slidesPerPage: numberOfVisibleSlides,
                on: {
                    loaded: function () {
                        //console.log('Carousel loaded');
                    },
                    ready: function (instance) {
                        // fade in slides
                        let fadeInDelay = 50;
                        let transitionTime = 0.5;
                        const delayMaximum = 2;
                        let slideCounter = 0;
                        instance.slides.forEach((item) => {
                            slideCounter++;
                            //if (slideCounter < numberOfVisibleSlides) {
                            if (fadeInDelay < delayMaximum) {
                                transitionTime += 0.2;
                                fadeInDelay += fadeInDelay;
                            }
                            setTimeout(function() {
                                item.el.style.transition = 'opacity ' + + 's';
                                item.el.style.opacity = 1;

                            },500 + fadeInDelay);
                            //}

                        });
                    },
                }
            };
            new Carousel(carousel, options);
            Fancybox.bind('[data-fancybox="' + galleryGroup + '"]', {
                // Your custom options
            });
        });
        //console.log(carousels.length + ' Fancybox Carousels initialized');
    }

    //initFancyboxCarousels();

    function initHeaderSwiper(initialSlide) {
        //console.log('initHeaderSwiper at slide ' + initialSlide);
        if (document.getElementsByClassName('swiper').length > 0 ) {
            Alp.mySwiper = new Swiper('.swiper', {
                hashNavigation: {
                    watchState: true,
                },
                effect: "coverflow",
                grabCursor: true,
                centeredSlides: true,
                initialSlide: initialSlide,
                //autoScrollOffset: 1,
                loop: true,
                loopAdditionalSlides: 1,
                slidesPerView: 1,
                coverflowEffect: {
                    rotate: 0, // 50
                    stretch: 150, // 50 abstand der slides
                    depth: 1,
                    scale: 0.45, // 0.7
                    modifier: 1,
                    slideShadows: false,
                },
                // If we need pagination
                pagination: {
                    el: '.swiper-pagination',
                },

                // Navigation arrows
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },

                // And if we need scrollbar
                scrollbar: {
                    el: '.swiper-scrollbar',
                },
            });
            //console.log('Header swiper initialized');
        }
    }

    function playVideo(delay=1000) {

        if(!video) {
            //console.error('No video on page');
            return;
        }

        // prevent NotAllowedError (DOMException) when trying to play video
        // autoplay or play() is only allowed after a user interaction
        if (document.readyState === 'interactive' || document.readyState === 'complete') {
            try {
                setTimeout(() => {
                    video.play().then(()=>{
                        //console.log('Video playing...');
                    }).catch(error => {
                        if (error.name !== 'NotAllowedError') {
                            console.error('Error playing video:', error);
                        }
                    });
                }, delay); // 1 second delay
            } catch (error) {
                console.info('Error playing video:', error);
            }
        } else {
            document.addEventListener('click', () => {
                try {
                    setTimeout(() => {
                        video.play().then(()=>{
                            //console.log('Video playing...');
                        }).catch(error => {
                            if (error.name !== 'NotAllowedError') {
                                console.error('Error playing video:', error);
                            }
                        });
                    }, delay); // 1 second delay
                } catch (error) {
                    console.info('Error playing video:', error);
                }
            }, { once: true });
        }
    }



    function initPageUpButton() {

        window.addEventListener('scroll', function() {
            if (window.scrollY > 300) {
                pageUp.classList.add('show');
            } else {
                pageUp.classList.remove('show');
            }
        });

        pageUp.addEventListener('click', function(e) {
            e.preventDefault();
            window.scrollTo({ top: 0, behavior: 'smooth' });
        });

    }

    function animateWeatherIcons() {
        const svgContainer = document.getElementById('weather-icon');
        const svgFiles = [
            '/Public/Images/icons/weather/filled/clear-day.svg',
            '/Public/Images/icons/weather/filled/partly-cloudy-day.svg',
            '/Public/Images/icons/weather/filled/partly-cloudy-day-rain.svg',
            '/Public/Images/icons/weather/filled/thunderstorms-rain.svg',
            '/Public/Images/icons/weather/filled/windsock.svg',
            '/Public/Images/icons/weather/filled/wind-beaufort-9.svg',
            '/Public/Images/icons/weather/filled/wind-beaufort-12.svg',
            '/Public/Images/icons/weather/filled/cloudy.svg',
            '/Public/Images/icons/weather/filled/partly-cloudy-day.svg'
        ];

        // todo: maybe add winter weather icons
        const svgFilesWinter = [
            '/Public/Images/icons/weather/filled/thunderstorms-snow.svg',
        ]

        // Preload SVG images
        const frameCount = svgContainer.children.length
        if(frameCount === 0) {
            svgFiles.forEach((file, index) => {
                const img = document.createElement('img');
                img.src = file;
                if (index === 0) {
                    img.classList.add('active');
                }
                svgContainer.appendChild(img);
            })
        ;}

        const images = svgContainer.querySelectorAll('img');

        function showNextImage() {
            // set to index of desired weather icon
            // 1 = rain: partly-cloudy-day.svg
            if (currentWeatherIconIndex === 1) {
                if(video.paused) {
                    // start video
                    //console.log('try to start video');
                    playVideo(0);
                }
            }
            // set to index of desired weather icon
            if (currentWeatherIconIndex === images.length - 2) {
                console.log('Last image',images.length);
                if(!video.paused) {
                    // pause video
                    //console.log('try to pause video');
                    video.pause();
                }
            }

            // see video.eventListners for play/pause
            images[currentWeatherIconIndex].classList.remove('active');
            currentWeatherIconIndex = (currentWeatherIconIndex + 1) % images.length;
            images[currentWeatherIconIndex].classList.add('active');
        }
        setInterval(showNextImage, 3000); // Change image every 3 seconds
    }


});