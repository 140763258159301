document.addEventListener('DOMContentLoaded', function() {
    const dropdownToggle = document.querySelector('.dropdown-toggle-theme');
    const dropdownMenu = document.querySelector('.dropdown-menu-theme');
    const selectedIcon = document.querySelector('.dropdown-toggle-theme i');
    const options = dropdownMenu.querySelectorAll('li');
    const themePicker = document.getElementById('theme-picker');

    // The name under which the user's color scheme preference will be stored.
    const colorSchemeStorageItemName = "preferredColorScheme";

    if (themePicker) {

        dropdownToggle.addEventListener('click', function() {
            dropdownMenu.classList.toggle('show');
        });

        options.forEach(option => {

            const tooltip = option.querySelector('[role="tooltip"]');
            const input = option.querySelector('input');
            const arrow = option.querySelector('.tt-arrow');

            option.addEventListener('click', function() {
                input.addEventListener('click', function(event) {
                    event.stopPropagation();
                });

                const colorScheme = input.value;
                dropdownToggle.setAttribute('data-selected', colorScheme);
                selectedIcon.classList.remove(selectedIcon.classList[1]);
                selectedIcon.classList.add(input.getAttribute('data-icon'));
                dropdownToggle.setAttribute('data-icon', input.getAttribute('data-icon'));

                localStorage.setItem(colorSchemeStorageItemName, colorScheme)
                dropdownMenu.classList.remove('show');
                updateDropdown();
            });
            option.addEventListener('mouseenter', function() {
                showTooltip(input, tooltip, arrow);
            });
            option.addEventListener('mouseleave', function() {
                hideTooltip(tooltip);
            });
        });

        // get stored colorScheme
        const colorScheme = localStorage.getItem(colorSchemeStorageItemName);
        if (!colorScheme) { return; }

        const option = themePicker.querySelectorAll(`[value=${colorScheme}]`);
        if (option.length === 0) { return; }

        const icon = option[0].getAttribute('data-icon');
        option.checked = true;
        selectedIcon.classList.remove(selectedIcon.classList[1]); // delete the previous icon class
        selectedIcon.classList.add(icon); // add the new icon class
        dropdownToggle.setAttribute('data-selected', colorScheme);

        updateDropdown();

        themePicker.addEventListener( 'mouseleave', function() {
            dropdownMenu.classList.remove('show');
        });
    }

    function updateDropdown() {
        const selectedValue = dropdownToggle.getAttribute('data-selected');
        const selectedIcon = dropdownToggle.getAttribute('data-icon');
        options.forEach(option => {
            const input = option.querySelector('input');
            if (input.value === selectedValue) {
                input.checked = true;
            } else {
                input.checked = false;
            }
        });
    }

    function initTooltip(button, tooltip, arrowElement) {
        Alp.computePosition(button, tooltip, {
            placement: 'right',
            middleware: [
                Alp.offset(1),
                Alp.flip(),
                Alp.shift({padding: 5}),
                Alp.arrow({element: arrowElement}),
            ],
        }).then(({x, y, placement, middlewareData}) => {
            Object.assign(tooltip.style, {
                left: `${x}px`,
                top: `${y}px`,
            });

            // Accessing the data
            const {x: arrowX, y: arrowY} = middlewareData.arrow;

            const staticSide = {
                top: 'bottom',
                right: 'left',
                bottom: 'top',
                left: 'right',
            }[placement.split('-')[0]];

            Object.assign(arrowElement.style, {
                left: arrowX != null ? `${arrowX}px` : '',
                top: arrowY != null ? `${arrowY}px` : '',
                right: '',
                bottom: '',
                [staticSide]: '-4px',
            });
        });
    }

    function showTooltip(button, tooltip, arrow) {
        tooltip.style.display = 'block';
        initTooltip(button, tooltip, arrow);
    }

    function hideTooltip(tooltip) {
        tooltip.style.display = '';
    }

});
